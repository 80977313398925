.custom-modal-size {
    max-width: 100vw !important;
    margin-left: 10% !important;
    width: 100% !important;
    height: 100vh !important;
}
.custom-modal-video {
    max-width: 100vw !important;
    width: 85% !important;
    height: 80% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-video-conteudo {
    margin-left: 10% !important;
}
.custom-modal-iframe {
    height: calc(100vh - 126px);
    border: none;
}
.materials-list {
    position: relative;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem; 
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.play-icon:hover {
    transform: translate(-50%, -50%) scale(1.2);
}

.img-fluid {
    position: relative;
    width: 100%;
}

.category-list {
    max-height: 200px; 
    overflow-y: auto;  
    padding-right: 5px; 
  }