.titulos-modal-secao{
  width: 100%;
  font-size: 25px;
  margin: 10px 0;
}
button.submit-button{
  margin: 20px 20px 0 0;
  
}
.form-container-cad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Ou outro valor que preferir, como space-around ou space-evenly */
}

.form-group {
  flex-basis: calc(33.33% - 10px);
  /* 33.33% para 3 campos por linha */
  margin-bottom: 20px;
  /* Ajuste o espaçamento entre as linhas conforme necessário */
  box-sizing: border-box;
}

.alunos-container {
  max-height: 300px; /* Defina a altura máxima conforme necessário */
  overflow-y: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.input-field {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.input-field-filter {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
}





.search-group {
  position: relative;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-wrapper .input-field {
  width: 100%;
  padding-right: 30px; /* espaço para o ícone */
}

.search-icon {
  position: absolute;
  right: 20px;
  font-size: 1.2em;
  color: #aaa;
}

/* Estilo para cada campo de entrada */
.input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  /* Outros estilos que você queira aplicar aos campos de entrada */
}

/* Para ajustar para 2 campos por linha em telas menores */
@media screen and (max-width: 768px) {
  .form-group {
    flex-basis: calc(50% - 10px);
    /* 50% para 2 campos por linha */
  }
}

.form-group-2{
  width: 100%;
  margin-bottom: 20px;
}


/* Estilização do checkbox */
input.styled-checkbox[type=checkbox] {
  position: relative;
  margin-top: 20px;
  cursor: pointer;
}

input.styled-checkbox[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  border: 1px solid #771718;
  background-color: white;
}

input.styled-checkbox[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #771718;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
  left: 9px;
}


.span-check {
  margin: 30px 0 0 25px;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.required {
  color: #771718;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 700;
}

.status-td {
  align-content: center;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}
.status-cell {
  display: flex;
  align-items: center;
  height: 100%; 
  padding:  5px 0;
}
.status-cell span {
  width: 100%; 
  display: inline-block;
  text-align: center; 
}

td.status-td {
  border: none;
}

.status-td {
  padding: 0; 
  vertical-align: top; 
  display: table-cell; 
}

.status-cell {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 100%; 
  height: 100%; 
  padding: 8px; 
}

.ativo span {
  background-color: green;
}

.cancelado span {
  background-color: red;
}

.trancado span {
  background-color: orange;
}

.concluido span {
  background-color: rgb(5, 170, 224);
}

.ativo span,
.cancelado span,
.trancado span,
.concluido span {
  display: flex;
  margin: auto;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 3px 5px;
  font-weight: 500;
  color: white;
  width: 120px !important;
}

/* Estilo para o container dos campos de horário */
.horario-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Estilo para os campos de horário */
.horario-container .input-field {
  width: 48%; /* Defina o tamanho desejado para os campos */
}

.horario-span{
  margin: 12px 10px 0 0;
}

.categories-container{
  background-color: #f2f2f2;
  padding: 7px 10px;
}

.lista-categorias{
  background-color: #f2f2f2;
  padding: 7px 10px;
}

.custom-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* .modal-custom-height .modal-content {
  height: 200px!important; 
  max-height: 70vh!important; 
} */