/* Reset de estilos */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-color: #771718; */
  /* Fundo cinza claro */
}

/* Estilos do container */
.container-cipex {
  display: flex;
  padding: 25px 15px 0 15px;
  background-color: #e0e0e0;
  background-image: url("./pages/arquivos/fundo-cipex-inicio.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  height: 100%;
  justify-content: center center;
  border-top: 5px solid #771718;
  /* border-radius: 12px; */

  /* width: 490px;
    max-width: 490px;
    height: 80vh;
    max-height: 779px;
    background-image: url("./pages/arquivos/estudante.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 9px 0 0 9px;
    overflow: hidden; */
}

/* Estilos das colunas */
.coluna {
  height: 100%;
}

.coluna-70 {
  /*width: 70%;*/
  width: 75%;
  margin: 0 auto;

}

.coluna-30 {
  width: 25%;
  display: flex;
  flex-direction: column;
  /* Adicione esta linha para exibir os itens em coluna */
  justify-content: right;
  padding-left: 2%;
}



/* ------------  */

@media (min-width: 768px) {

  .menu-toggle {
    display: none;
  }
}

@media (max-width: 767px) {
  .coluna-30 {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 60%;
    z-index: 1000;
    transition: right 0.3s ease;
    background-color: #D9D9D9;
    padding: 20px;
  }

  .menu-aberto .coluna-30 {
    right: 0;
  }

  .hamburger-icon {
    color: white;
  }

  .container-cipex {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .linha-retangulos {
    justify-content: center;
  }

  .coluna-70 {
    width: 100% !important;
    transition: margin-right 0.3s ease;
  }

  .coluna-30.menu-aberto {
    display: block;
  }

  .menu-toggle {
    display: flex;
    transition: margin-right 0.3s ease;
    /* Adiciona transição suave */
    margin-right: 0;
    /* Garante que o ícone permaneça visível mesmo quando o menu está aberto */
  }

  .menu-aberto .menu-toggle {
    margin-right: 60%;
    /* Empurra o ícone para a esquerda quando o menu está aberto */
  }

  .header-content {
    display: flex;
    flex-direction: column;
  }
  .header-content h1{
    width: 100%;
    margin-bottom: 10px; 
    justify-content: center;
    display: flex;
  }

  .header-controls {
    display: flex;
    text-align: center;
    justify-content: center!important ;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    /* Adiciona uma quebra de linha */
  }
  .search-form{
    margin: 0!important;
    padding: 2px;
  }
  .retangulo{
    width: 96px!important;
  }

}

.menu-toggle {
  /* display: none; */
  position: fixed;
  top: 30px;
  right: 0px;
  /* Ajuste conforme necessário */
  z-index: 999;
  background-color: #7b0025;
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.menu-toggle .hamburger-icon {
  color: white;
}

/* Estilos do retângulo */
.retangulo {
  width: 125px;
  height: 84px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  text-align: center;
  align-items: center !important;
  cursor: pointer;
  margin: 0 10px 20px 10px;
}

/* Efeito ao passar o mouse no retângulo */
.retangulo:hover {
  transform: translateY(-3px);
}

/* Mudar cursor ao passar por cima */
.retangulo:hover,
.texto span:hover {
  cursor: pointer;
}

/* formatação sessão 70% */
.linha-retangulos {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* justify-content: flex-end; */
  /* margin: -5px 0; */
  margin-top: -5px;
  margin-bottom: 15px;
  padding: 10px 10px 0 !important;
  background-color: white;
  border-radius: 7px;

}

.linha-conteudo {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  align-content: center;
  width: 100%;
  height: auto;

}

/* Estilos para os quadrados */

.Quadrado0 .retangulo {
  background-color: #69140e;
}

.Quadrado0 .icone {
  color: #69140e;
}

.Quadrado1 .retangulo {
  background-color: #5fb49c;
}

.Quadrado1 .icone {
  color: #5fb49c;
}

.Quadrado2 .retangulo {
  background-color: #f45b69;
}

.Quadrado2 .icone {
  color: #f45b69;
}

.Quadrado3 .retangulo {
  background-color: #414288;
}

.Quadrado3 .icone {
  color: #414288;
}

.Quadrado4 .retangulo {
  background-color: #119da4;
}

.Quadrado4 .icone {
  color: #119da4;
}

.Quadrado5 .retangulo {
  background-color: #f87575;
}

.Quadrado5 .icone {
  color: #f87575;
}

.Quadrado6 .retangulo {
  background-color: #1930FF;
}

.Quadrado6 .icone {
  color: #1930FF;
}

.Quadrado7 .retangulo {
  background-color: #B519FF;
}

.Quadrado7 .icone {
  color: #B519FF;
}

.Quadrado8 .retangulo {
  background-color: #02AF47;
}

.Quadrado8 .icone {
  color: #02AF47;
}

.Quadrado9 .icone {
  color: #011627;
}

.Quadrado9 .retangulo {
  background-color: #011627;
}

.Quadrado10 .retangulo {
  background-color: #007bff;
}

.Quadrado10 .icone {
  color: #007bff;
}


.Quadrado1,
.Quadrado2,
.Quadrado3,
.Quadrado4,
.Quadrado5,
.Quadrado6,
.Quadrado7,
.Quadrado8,
.Quadrado9,
.Quadrado0,
.Quadrado10 {
  text-decoration: none !important;
}

.retangulo {
  transition: all .35s;
  display: flex;
  position: relative;

}

a .retangulo .icone,
a .retangulo .texto {
  position: relative;
  z-index: 2;
}

a.Quadrado0 .retangulo:after {
  position: absolute;
  content: "";
  top: 15px;
  left: 0;
  width: 0;
  height: 40%;
  background: #812c2d !important;
  transition: all .35s;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

a .retangulo:after {
  position: absolute;
  content: "";
  top: 15px;
  left: 0;
  width: 0;
  height: 40%;
  background: #771718;
  transition: all .35s;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

a .retangulo:hover {
  color: #fff;
}

a .retangulo:hover:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}



/* Estilos do conteúdo dentro do retângulo */
.conteudo {
  color: #fff;
  text-align: left;
  padding-left: 10px;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Estilos do ícone */
.icone {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

}

/* Estilos do texto */
.texto span,
.texto,
.texto span a {
  text-decoration: none !important;
}

/* Estilos para o quadrado dividido */
.quadrado-dividido {
  width: 93%;
  height: 300px;
  border-radius: 10px;
  background-image: linear-gradient(#771718 50%, #fff 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* Estilos para a foto de perfil */
.foto-perfil {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /*background-color: #ccc;*/
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-size: cover;
  /* Adicione essa propriedade */
}

/* Estilos para o nome do usuário */
.nome-usuario {
  font-size: 20px;
  margin-top: 190px;
  margin-bottom: 10px;
  color: #771718;
}

/* Estilos para o botão Sair */
.botao-sair {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background-color: #771718;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: all ease 0.4;
}

.botao-sair:hover {
  transform: translateY(-3px);
  /* Efeito de elevação */
  cursor: pointer;


}

/* Adicione este código ao seu arquivo CSS */

.edit-profile-icon {
  position: absolute;
  bottom: 7px;
  right: 25px;
  border: 2px solid white;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 50%;
  background-color: #771718;
  font-size: 30px;
  padding: 5px;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  outline: none;

  /* z-index: 9999;
  padding: 20px;
  */
}



.edit-profile-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}