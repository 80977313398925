.login-container {
    background: url('../components/pages/arquivos/gradiente.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    padding: 0;
    overflow: hidden; 
  }
  
  .logo-container {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .logo {
    width: 150px;
  }
  
  .frase {
    max-width: 80%;
    margin: auto;
  }
  
  .login-form {
    background-color: #fff;
    padding: 100px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 20px; 
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form .btn-primary {
    background-color: #b00b1d;
    border-color: #b00b1d;
  }
  
  .login-form .btn-primary:hover {
    background-color: #900e1b;
    border-color: #900e1b;
  }
  
  /* .form-error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25rem;
  }
  
  .error-message {
    color: red;
    font-size: 0.875em;
    text-align: center;
    margin-top: 1rem;
  } */
  