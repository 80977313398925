.select-pagi {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  margin-right: 5px;
  border: none;
  background-color: #d3d3d3;
  color: #771718;
  height: 42px;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}
/* Estilos para o cabeçalho das colunas */
.column-headers {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

/* Estilos para cada título das colunas */
.column-headers > div {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

/* Estilos para os dados */
.user-data {
  display: flex;
  flex-direction: column;
}

/* Estilos para cada linha de dados */
.user-data > div {
  display: flex;
}

/* Estilos para cada item de dado */
.user-data > div > div {
  flex: 1;
  padding: 3px 16px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Estilos para a tabela */
.user-table {
  display: flex;
  min-height: 240px !important;
  flex-direction: column;
  background-color: #f5f5f5;
}

/* Estilos para as linhas da tabela */
.user-table > div:nth-child(even) {
  background-color: #f5f5f5;
}

.user-table > div:nth-child(odd) {
  background-color: #ffffff;
}

/* Estilos para os elementos dentro das células da tabela */
.user-table > div > div {
  padding: 8px 16px;
  flex: 1;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* Estilos para os botões */
.add-button {
  padding: 4px 8px;
  border: none;
  background-color: #771718;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.add-button:hover {
  background-color: #771718;
}

/* Estilos para o cabeçalho */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
}

/* Estilos para o título 'Usuário' */
.user-title {
  color: #444;
  margin: 0;
}

/* Estilos para os controles à direita */
.header-controls {
  display: flex;
  align-items: center;
}

/* Estilos para a caixa de pesquisa */
.search-form {
  margin-right: 16px;
}

/* Estilos para o input de pesquisa */
.search-form input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Estilos para o botão 'Filtrar' */
.search-form button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  color: #771718;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
}

.search-form button:hover {
  background-color: #771718;
  color: #fff;
}

/* Estilos para os botões 'Adicionar' e 'Ordenar por' */
.buttons button {
  padding: 8px 12px;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.buttons button:hover {
  background-color: #ac291a;
  color: #fff;
}

/* Estilos para o texto nos botões */
.buttons button i {
  color: #771718;
  margin-right: 4px;
}

/* Estilos para os botões de edição e exclusão */
.excluir-td .add-button,
.edit-td .add-button {
  padding: 8px;
  border: none;
  cursor: pointer;
  background-color: #771718;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.excluir-td .add-button:hover,
.edit-td .add-button:hover {
  background-color: #ac291a;
}

/* Estilos para os ícones nos botões de edição e exclusão */
.excluir-td .add-button i,
.edit-td .add-button i {
  color: #771718;
}

@media only screen and (max-width: 600px) {
  .ocultar-no-celular {
    display: none;
  }
  .custom-select {
    width: 246px!important; 
  }
}


.custom-select {
  width: 150px; 
}

.custom-input {
  flex-grow: 1;
}