/* Título da agenda */
.agenda-titulo {
    text-align: center;
    padding: 10px 0;
}

/* Estilos gerais da agenda */
.agenda-container {
    position: relative;
    width: 100%;
    display: flex;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0;
}

.agenda-header {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;
}

/* Tamanho dos títulos e datas da agenda */
.agenda-today {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    color: #333;
}

.agenda-today h1 {
    font-size: 35px;
    font-weight: 800;
    color: #771718;
}

.agenda-day {
    font-size: 35px;
    font-weight: 800;
    color: #771718;
}

.agenda-today h3 {
    color: #000;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.agenda-today h3::first-letter {
    text-transform: uppercase;
}

.agenda-events {
    background-color: #d9d9d9;
    margin-top: 5px;
}

.events-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.event-row {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.day-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.day-box {
    background-color: #ccc;
    width: 90px;
    min-width: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: bold;
}

.day-box.today {
    background-color: yellow;
}

.event-col {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.event-name {
    width: 100%;
    margin: 0;
    align-items: center;
    padding-left: 10px;
    font-size: 1rem;
}

@media (max-width: 1030px) {
    .agenda-container {
        text-align: justify !important;
    }

    .day-box {
        width: 70% !important;
    }

    .event-col, .day-col {
        width: 100% !important;
    }
}

/* Estilo para aniversariantes */
.aniversariantes-container {
    width: 100%;
    padding: 10px 0;
    background-color: transparent;
    margin-bottom: 20px;
}

.aniversariantes-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.birthday-row {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.birthday-name {
    font-size: 1rem;
    font-weight: bold;
    color: #771718;
    text-align: center;
}

.birthday-date {
    font-size: 0.9rem;
    color: #555;
    text-align: center;
}

/* Botão "Ver Mais" */
.btn-ver-mais {
    color: #771718;
    font-weight: bold;
    text-decoration: none;
}

.btn-ver-mais:hover {
    color: #555;
}

/* Estilo do modal para aniversariantes */
.modal-header {
    background-color: #f9f9f9;
}

.birthday-row {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.birthday-name {
    font-size: 1rem;
    font-weight: bold;
    color: #771718;
    text-align: center;
}

.birthday-date {
    font-size: 0.9rem;
    color: #555;
    text-align: center;
}

/* Botão de ver mais no modal */
.btn-ver-mais {
    display: block;
    margin: 10px auto;
}

.btn-ver-mais:hover {
    color: #555;
}

/* Estilo do botão redondo com ícone de presente */
.present-button {
    background-color: #771718;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    top: -20px;
    left: 44%;
    /* transform: translateX(-50%); */
}

.present-button:hover {
    background-color: #555;
}

.gift-icon {
    color: #fff;
    font-size: 20px;
}

.present-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}