.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: block;
}

.modal-content-matricula {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* max-width: 600px; */
  height: auto;
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.usuario-lista {
  list-style-type: none;
  padding: 0;
}

.usuario-lista li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.usuario-lista li:hover {
  background-color: #f1f1f1;
}
