.audio-modal {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  
}

.audio-player {
  width: 300px!important;

  min-width: 300px; 
  margin-top: 10px; 
}

@media (max-width: 768px) {
  
  .audio-player {
      margin-top: 5px; 
  }
}


.audio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.audio-header h2 {
  font-size: 24px; 
  color: #333; 
}

.audio-list {
  
  overflow-y: auto;
  list-style: none;
  padding: 0;
}

.audio-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.audio-details {
  display: flex;
  align-items: center;
}

.audio-title {
  font-weight: bold;
  margin-right: 20px;
  flex-grow: 1;
  font-size: 16px;
  color: #333;
}
.audio-controls {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 5px 10px;
}

.audio-controls button {
  padding: 8px;
  border: none;
  cursor: pointer;
  background-color: #771718;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.audio-controls button:hover {
  background-color: #950d0d;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border: none;
  cursor: pointer;
  background-color: #771718;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #950d0d;
}


.ver-audios-td button {
  padding: 8px;
  border: none;
  cursor: pointer;
  background-color: #771718;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ver-audios-td button:hover {
  background-color: #ac291a;
}


.media-control--play-pause {
  visibility: hidden;
}

.media-control.media-control--volume {
  display: flex;
  align-items: center;
}

.media-control.media-control--current-time,
.media-control.media-control--duration,
.media-control.media-control--mute-unmute,
.media-control.media-control--volume {
  margin-left: 10px; 
}

.volume-label {
  margin-right: 10px; 
}

.volume-bar {
  width: 150px; 
  height: 10px;
  background-color: #ccc;
  border-radius: 5px;
}

.media-control--volume::-moz-range-thumb,
.media-control--volume {
  height: 100%;
  background-color: #771718; 
  border-radius: 5px;
}

input[type="range"] {
  -webkit-appearance: none; 
  width: 100px; 
  height: 10px; 
  background-color: #a36667; 
  border-radius: 5px; 
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; 
  width: 20px; 
  height: 20px; 
  background-color: #771718; 
  border-radius: 50%; 
  cursor: pointer; 
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none; 
  width: 220px; 
  height: 220px; 
  background-color: #771718; 
  border-radius: 50%; 
  cursor: pointer; 
}