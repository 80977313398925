.certificado-container {
  font-family: "Times New Roman", serif;
}

.modal-body-custom {
  background-image: url("./arquivos/CERTIFICADO_2024_NOVO.png");
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 842px;
  max-width: 842px;;
  height: 595px;
  max-height: 595px;
  margin: 0;
  padding: 90px 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: black; */
  text-align: center;
  /* font-family: 'Dancing Script', cursive; */
  /* font-size: 22px; */
  margin: auto;
}

.certificado-content {
  width: 100%;
  padding: 10px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  border-radius: 10px;
  font-family: "Times New Roman", serif;
  max-width: 700px;
}

.certificado-content p {
  /* font-size: 22px; */
}

.certificado-content h2 {
  font-size: 32px;
  margin-bottom: 10px;
  /* font-family: 'Dancing Script', cursive; */
}
