.card-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}


.card-body {
    /* white-space: nowrap; */
    overflow-x: auto;
}