/* styles.css */

/* Estilos para o contêiner do formulário */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* Estilos para os campos de entrada */
.input-field {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
}

/* Estilos específicos para o botão */
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #771718;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.submit-buttonB {
  background-color: #771718;
  color: white;
  cursor: pointer;
}

/* Estilos para mensagens de erro/sucesso */
.error-message {
  color: #ff0000;
  margin-bottom: 10px;
}

.success-message {
  color: #007700;
  margin-bottom: 10px;
}


/* tabela de vizualização */
.form-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

h1 {
  margin: 0;
}

.search-form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  /* Move o formulário para a direita */
}

.search-form input[type="text"] {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.search-form button {
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.buttons {
  display: flex;
  gap: 10px;
}

.add-button {
  background-color: #771718;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
}

.sort-button {
  background-color: #ccc;
  color: #333;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: not-allowed;
}

.user-table-container {
  width: 100%;
  
}

/* .user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
} */

/* Estilos CSS para o Modal */

.modal-background {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #f0f0f0;
  width: 80%;
  height: 80%;
  border-radius: 8px!important;
  /* position: relative; */
}
.modal-form{
  overflow-y: scroll; /* ajustes */
  margin: 20 2px;
  padding: 20px;
  
}
.close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  float: right!important;
  margin-top: -18px;
  margin-right: -18px;
  background-color: #771718;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  color: #ccc;
  cursor: pointer;
  font-size: 18px;
  
}

.modal-content button {
  z-index: 999;
  /* position: fixed; */
  
}

/* CSS para paginação */


.pagination-container {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.pagination-numero {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  /* Centralizando horizontalmente */
}

.pagination-numero li {
  display: inline-block;
}

.pagination-numero li button {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  margin-right: 5px;
  border: none;
  background-color: #d3d3d3;
  color: #771718;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.pagination-numero li button:hover {
  background-color: #ffffff;
  color: #771718;
}

.pagination-numero li.active button {
  background-color: #ffffff;
  color: #771718;
}