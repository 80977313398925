.image-container {
  position: relative;
  display: inline-block;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  pointer-events: none;
}